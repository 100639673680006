import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { ItemMap } from "../../types/item-map";

interface Props {
  children?: React.ReactNode;
}

interface ContextProps {
  components: ItemMap;
  setComponents: Dispatch<SetStateAction<ItemMap>>;
}

const GraphicComponentsContext = createContext<ContextProps>({
  components: {},
  setComponents: () => {},
});

const GraphicComponentsProvider: FC<Props> = ({ children }) => {
  const [components, setComponents] = useState<ItemMap>({});

  return (
    <GraphicComponentsContext.Provider value={{ components, setComponents }}>
      {children}
    </GraphicComponentsContext.Provider>
  );
};

export const useGraphicComponentsContext = () =>
  useContext(GraphicComponentsContext);

export default GraphicComponentsProvider;
