import React, { useCallback, useEffect, Fragment } from 'react';


import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { BuildingState } from '../../redux/types/building.type';
import { fetchBuildings } from '../../redux/actions/building.action';
import { StoreState } from '../../redux/types/store.type';

import {ThemeOptionsState} from "../../redux/types/themeOptions.type";

interface OwnProps {
    placeholder?: string;
}

type MapStateToPropsType = {
    ThemeOptions: ThemeOptionsState;
    Buildings: BuildingState;
};

type MapDispatchToPropsType = {
    fetchBuildings: () => void;
  };

type Props = ConnectedProps<typeof connector> & OwnProps & MapStateToPropsType & MapDispatchToPropsType;

const useBuildingActions = () => {
    const dispatch = useDispatch<ThunkDispatch<BuildingState, unknown, AnyAction>>();
  
    const memoizedFetchBuildings = useCallback(() => dispatch(fetchBuildings()), [dispatch]);
  
    return {
      fetchBuildings: memoizedFetchBuildings
    };
  };

const BuildingDataService: React.FC<Props> = ({ ThemeOptions, Buildings, fetchBuildings }) => {
    useEffect(() => {
        fetchBuildings();
      }, [fetchBuildings]);

    return (
        <>

        </>
    );
};

const mapStateToProps = (state: StoreState.All): MapStateToPropsType => {
    return {
        ThemeOptions: state.ThemeOptions,
        Buildings: state.Buildings
    };
};

const connector = connect(mapStateToProps);

export default connector((props: Props) => {
    const { fetchBuildings } = useBuildingActions();
  return <BuildingDataService {...props} fetchBuildings={fetchBuildings}/>;
});
