import { TextFieldComponentProps } from "../../types/draggable-component";

function Textfield({
  disabled = true,
  placeholder = "12.5",
  className,
  backgroundColor = "#f9fafb",
  fontSize = 12,
  fontColor = "#111827",
}: TextFieldComponentProps) {
  return (
    <input
      disabled={disabled}
      className={`appearance-none border border-gray-300 text-xs rounded-lg w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${className}`}
      id="username"
      type="text"
      placeholder={placeholder}
      style={{
        backgroundColor,
        fontSize,
        color: fontColor,
        lineHeight: "1rem",
      }}
    />
  );
}

export default Textfield;
