import React, { useEffect } from "react";
import { Route, Routes, useNavigate, Outlet } from "react-router-dom";


import AppHeader from "../../layout/AppHeader";
import AppSideBar from "../../layout/AppSideBar";
// import AppFooter from "../../Layout/AppFooter/";
import BuildingDataService from "../../firebase/services/building.service"
import firebase from "firebase/compat/app";
type Props = {
    user: firebase.User | null
  };

const Dashboards: React.FC<Props> = ({ user }) => {
    // const navigate = useNavigate();

    // useEffect(() => {
    //     navigate("ahu/")

    //   }, []);

    
    return (
        <>
            <AppHeader user={user}/>
            <BuildingDataService fetchBuildings={() => undefined}/>
            <div className="app-main"> 
                <AppSideBar user={user} setEnableMobileMenu={() => undefined}/>
               <div className="app-main__outer">
                    <div className="app-main__inner">
                        {/* <Routes>
                            <Route path={`ahu/:level`} element={<AHUDashboard/>} /> 
                            <Route path={`ahu`} element={<AHUDashboard/>} />
                            <Route path={`energy`} element={<ENERGYDashboard/>} />
                        </Routes> */}
                            <Outlet/>
                        {/* <AHUDashboard/> */}
                    </div> 
                    {/* <AppFooter /> */}
               </div>
            </div>  
        </>
    );    
}
export default Dashboards;
