import { useState } from "react";
import Icon from "../../icons";
import SettingsDropdown from "./settings-dropdown";

interface BottomBarProps {
  onZoom: (num: number) => void;
}

const BottomBar = ({ onZoom }: BottomBarProps) => {
  const [displaySettingsDropdown, setDisplaySettingDropdown] =
    useState<boolean>(false);

  return (
    <div
      className="h-16 bg-white rounded absolute bottom-4 shadow-lg px-2"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* TODO: zoom in / zoom out */}
      {/* <Icon
        icon="zoomOut"
        className="w-10 h-10 p-2 !pl-0 text-gray-700 cursor-pointer hover:bg-slate-100 rounded"
        onClick={() => onZoom(-100)}
      />
      <Icon
        icon="zoomIn"
        className="w-10 h-10 p-2 text-gray-700 cursor-pointer hover:bg-slate-100 rounded"
        onClick={() => onZoom(100)}
      /> */}

      <button
        type="button"
        className="text-gray-700 cursor-pointer hover:bg-slate-100 rounded pr-2"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => setDisplaySettingDropdown(!displaySettingsDropdown)}
      >
        <Icon icon="setting" className="w-10 h-10 p-2" />
        <span>Settings</span>
      </button>
      <SettingsDropdown
        displayDropdown={displaySettingsDropdown}
        closeDropdown={() => setDisplaySettingDropdown(false)}
      />
      <div className="h-[calc(100%-1rem)] w-[1px] m-2 bg-slate-300" />
      <button
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
        type="button"
      >
        Save
      </button>
    </div>
  );
};

export default BottomBar;
