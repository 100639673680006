import { CSSProperties, FC, memo, useEffect } from "react";
import { DragSourceMonitor, useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useSelectorContext } from "../../components/providers/selector-provider";
import { useSettingsContext } from "../../components/providers/settings-provider";
import {
  ComponentTypes,
  DraggableComponentProps,
} from "../../types/draggable-component";
import { GraphicEditorSettings } from "../../types/settings";

function getStyles(
  left: number,
  top: number,
  isDragging: boolean,
  selectedComponent: boolean = false,
  settings: GraphicEditorSettings
): CSSProperties {
  const transform = `translate3d(${left}px, ${top}px, 0)`;
  return {
    position: "absolute",
    transform,
    WebkitTransform: transform,
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
    opacity: isDragging ? (settings.componentDragTransparency ? 0.4 : 0) : 1,
    // height: isDragging ? 0 : ""
    outline: selectedComponent ? "2px solid #7e8ff9" : undefined,
    outlineOffset: 5,
    borderRadius: selectedComponent ? 5 : undefined,
  };
}

interface ExtendedDraggableComponentProps extends DraggableComponentProps {
  children: React.ReactNode;
  defaultDragPreview?: boolean;
  isSelectable?: boolean;
}

export const DraggableContainer: FC<ExtendedDraggableComponentProps> = memo(
  function DraggableContainer(props) {
    const { id, left, top, children, className, isSelectable } = props;
    const { selectedComponent, setSelectedComponent } = useSelectorContext();
    const { settings } = useSettingsContext();

    const [{ isDragging }, drag, preview] = useDrag(() => {
      let componentStyle: string | undefined = className;
      if (props.defaultDragPreview) {
        componentStyle = getDefaultComponentStyle();
      }
      return {
        type: props.type,
        item: { ...props, className: componentStyle },
        collect: (monitor: DragSourceMonitor) => ({
          isDragging: monitor.isDragging(),
        }),
      };
    }, [props]);

    function getDefaultComponentStyle(): string | undefined {
      switch (props.type) {
        case ComponentTypes.VERTICAL_LABEL_TEXTFIELD:
          return "";
        case ComponentTypes.HORIZONTAL_LABEL_TEXTFIELD:
          return "flex items-center";
        default:
          return undefined;
      }
    }

    useEffect(() => {
      preview(getEmptyImage(), { captureDraggingState: true });
    });

    function isSelected() {
      return isSelectable && id === selectedComponent?.id;
    }

    return (
      <div
        ref={drag}
        className={`${className}`}
        style={getStyles(left, top, isDragging, isSelected(), settings)}
        role="DragableContainer"
        onClick={isSelectable ? () => setSelectedComponent(props) : undefined}
      >
        {children}
      </div>
    );
  }
);
