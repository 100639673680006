import "./index.css";
import { LabelTextfield, Label, TextField } from "../draggable-components";
import { ComponentTypes } from "../../types/draggable-component";
import { useSelectorContext } from "../providers/selector-provider";
import LabelEditor from "./components/label-editor";
import TextfieldEditor from "./components/textfield-editor";
import { useComponentDeletionContext } from "../providers/component-deletion-provider";

function Sidebar() {
  const { selectedComponent } = useSelectorContext();
  const { removeComponent } = useComponentDeletionContext();

  const renderComoponentStyleEditor = () => {
    const { type } = selectedComponent!;
    switch (type) {
      case ComponentTypes.VERTICAL_LABEL_TEXTFIELD:
      case ComponentTypes.HORIZONTAL_LABEL_TEXTFIELD:
        return (
          <div className="flex flex-col text-base font-normal">
            <LabelEditor />
            <div className="h-8" />
            <TextfieldEditor />
          </div>
        );
      case ComponentTypes.LABEL:
        return (
          <div className="flex flex-col text-base font-normal">
            <LabelEditor />
          </div>
        );
      case ComponentTypes.TEXTFIELD:
        return (
          <div className="flex flex-col text-base font-normal">
            <TextfieldEditor />
          </div>
        );
    }
  };
  return (
    <aside className="w-80 h-full float-right border-r" aria-label="Sidebar">
      <div className="overflow-y-auto bg-gray-50 h-full">
        <div className="py-4 px-3 bg-gray-50 rounded">
          <div className="flex items-center mb-5 text-base font-normal text-gray-900">
            <svg
              aria-hidden="true"
              className="mr-3 sm:h-7 flex-shrink-0 w-6 h-6 text-gray-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
            </svg>
            <span className="self-center text-sm font-medium whitespace-nowrap">
              Components
            </span>
          </div>
          <ul>
            {/* Label + Value */}
            <li className="flex items-stretch">
              <div className="pl-6 w-1/2 border-r-[1px] border-b-[1px]">
                <LabelTextfield
                  defaultDragPreview={true}
                  className="p-3 !relative"
                  id={"abc"}
                  left={0}
                  top={0}
                  type={ComponentTypes.VERTICAL_LABEL_TEXTFIELD}
                  label={{
                    text: "Label",
                    className: "mb-2",
                    fontSize: 12,
                    color: "#374151",
                  }}
                  textfield={{
                    disabled: true,
                    className: "w-[80px]",
                    backgroundColor: "#f9fafb",
                    fontSize: 12,
                    fontColor: "#111827",
                  }}
                />
              </div>

              <LabelTextfield
                defaultDragPreview={true}
                className="flex items-center justify-center p-3 w-1/2 !relative border-b-[1px]"
                id={"abc"}
                left={0}
                top={0}
                type={ComponentTypes.HORIZONTAL_LABEL_TEXTFIELD}
                label={{
                  text: "Label",
                  className: "mr-2",
                  fontSize: 12,
                  color: "#374151",
                }}
                textfield={{
                  disabled: true,
                  className: "w-[80px]",
                  backgroundColor: "#f9fafb",
                  fontSize: 12,
                  fontColor: "#111827",
                }}
              />
            </li>

            <li className="flex items-stretch">
              <Label
                className="grid place-items-center w-1/2 border-r-[1px] p-3 !relative"
                defaultDragPreview={true}
                id={"abc"}
                left={0}
                top={0}
                label={{ text: "Label/Text", fontSize: 12, color: "#374151" }}
                type={ComponentTypes.LABEL}
              />

              <TextField
                className="grid place-items-center w-1/2 p-3 !relative"
                defaultDragPreview={true}
                id={"abc"}
                left={0}
                top={0}
                textfield={{
                  className: "w-[80px]",
                  placeholder: "12.6",
                  backgroundColor: "#f9fafb",
                  fontSize: 12,
                  fontColor: "#111827",
                }}
                type={ComponentTypes.TEXTFIELD}
              />
            </li>
          </ul>

          <ul className="pt-4 mt-4 space-y-2 border-t border-gray-200">
            <li style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="flex items-center text-base font-normal text-gray-900">
                <svg
                  className="mr-3 sm:h-7 flex-shrink-0 w-5 h-5 text-gray-500"
                  fill="currentColor"
                  stroke="none"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                  ></path>
                </svg>
                <span className="self-center text-sm font-medium whitespace-nowrap">
                  Style editor
                </span>
              </div>
              {selectedComponent && (
                <div className="float-right">
                  <svg
                    onClick={removeComponent}
                    className="w-5 h-5 text-red-400 cursor-pointer"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    ></path>
                  </svg>
                </div>
              )}
            </li>
            <li>
              {selectedComponent ? (
                renderComoponentStyleEditor()
              ) : (
                <span className="self-center text-sm font-light whitespace-nowrap text-gray-400">
                  Select component to start edit
                </span>
              )}
            </li>
          </ul>
        </div>
      </div>
    </aside>
  );
}

export default Sidebar;
