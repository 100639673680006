import {combineReducers} from '@reduxjs/toolkit'

import ThemeOptions from "./ThemeOptions";
import Article from "./Article";
import Building from "./Building";

import { StoreState } from '../types/store.type';

const allReducers = combineReducers<StoreState.All>({
    ThemeOptions: ThemeOptions,
    Articles: Article,
    Buildings: Building
});

export default allReducers;