export interface DraggableComponentProps {
  id: any;
  type: ComponentTypes;
  top: number;
  left: number;
  className?: string;
  label?: LabelComponentProps;
  textfield?: TextFieldComponentProps;
}

export interface LabelComponentProps {
  className?: string;
  text?: string;
  fontSize?: number;
  color?: string;
}

export interface TextFieldComponentProps {
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  backgroundColor?: string;
  fontSize?: number;
  fontColor?: string;
}

export enum ComponentTypes {
  "LABEL" = "LABEL",
  "TEXTFIELD" = "TEXTFIELD",
  "VERTICAL_LABEL_TEXTFIELD" = "VERTICAL_LABEL_TEXTFIELD",
  "HORIZONTAL_LABEL_TEXTFIELD" = "HORIZONTAL_LABEL_TEXTFIELD",
}
