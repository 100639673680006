import { ChangeEvent, useCallback, useEffect, useState } from "react";
import update from "immutability-helper";
import { TextFieldComponentProps } from "../../../types/draggable-component";
import { useGraphicComponentsContext } from "../../providers/graphic-components-provider";
import { useSelectorContext } from "../../providers/selector-provider";
import ColorPicker from "./shared/color-picker";
import SizeInput from "./shared/size-input";
import TextInput from "./shared/text-input";
import { ColorResult } from "react-color";

function TextfieldEditor() {
  const { selectedComponent } = useSelectorContext();
  const { components, setComponents } = useGraphicComponentsContext();

  const [textfieldText, setTextfieldText] = useState<string>("");

  const initialTextfieldFontSize = selectedComponent?.textfield?.fontSize
    ? selectedComponent.textfield.fontSize
    : 12;

  const initialTextfieldFontColor = selectedComponent?.textfield?.fontColor
    ? selectedComponent.textfield.fontColor
    : "#111827";

  const initialTextfieldBackgroundColor = selectedComponent?.textfield
    ?.backgroundColor
    ? selectedComponent.textfield.backgroundColor
    : "#f9fafb";

  const [textfieldFontSize, setTextfieldFontSize] = useState<number>(
    initialTextfieldFontSize
  );
  const [textfieldFontColor, setTextfieldFontColor] = useState<string>(
    initialTextfieldFontColor
  );
  const [textfieldBackgroundColor, setTextfieldBackgroundColor] =
    useState<string>(initialTextfieldBackgroundColor);

  useEffect(() => {
    setTextfieldFontSize(selectedComponent!.textfield!.fontSize!);
    setTextfieldFontColor(selectedComponent!.textfield!.fontColor!);
    setTextfieldBackgroundColor(selectedComponent!.textfield!.backgroundColor!);
  }, [selectedComponent?.id]);

  const onTextfieldFontSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    let newFontSize: number = +event.target.value;
    if (!isFontSizeValid(newFontSize)) return;
    setTextfieldFontSize(newFontSize);
    updateTextfield(selectedComponent!.id, {
      ...components[selectedComponent!.id].textfield,
      fontSize: newFontSize,
    });
  };

  const updateTextfieldFontSize = (offset: number) => {
    let newFontSize: number =
      components[selectedComponent!.id].textfield!.fontSize! + offset;
    if (!isFontSizeValid(newFontSize)) return;
    setTextfieldFontSize(newFontSize);
    updateTextfield(selectedComponent!.id, {
      ...components[selectedComponent!.id].textfield,
      fontSize: newFontSize,
    });
  };

  const isFontSizeValid = (newFontSize: number) => {
    return newFontSize >= 1 && newFontSize <= 72;
  };

  const onColorChange = (color: ColorResult, property: string) => {
    if (property === "fontColor") {
      setTextfieldFontColor(color.hex);
    } else if (property === "backgroundColor") {
      setTextfieldBackgroundColor(color.hex);
    }
    updateTextfield(selectedComponent!.id, {
      ...components[selectedComponent!.id].textfield,
      [property]: color.hex,
    });
  };

  const updateTextfield = useCallback(
    (id: string, textfield: TextFieldComponentProps) => {
      setComponents(
        update(components, {
          [id]: {
            $merge: { textfield },
          },
        })
      );
    },
    [components]
  );

  return (
    <div className=" p-2.5 rounded shadow bg-white">
      <h4 className="font-normal text-sm mb-4">Textfield</h4>
      <TextInput
        title="Device point"
        placeholder="ie. AHU -> device_mode"
        value={textfieldText}
        onChange={(e) => setTextfieldText(e.target.value)}
      />

      <div className="flex mb-3" style={{ justifyContent: "space-between" }}>
        <ColorPicker
          title="Font Color"
          color={textfieldFontColor}
          onColorChange={(color: ColorResult) =>
            onColorChange(color, "fontColor")
          }
          left="left-1"
        />
        <ColorPicker
          title="Background color"
          color={textfieldBackgroundColor}
          onColorChange={(color: ColorResult) =>
            onColorChange(color, "backgroundColor")
          }
          triangle="top-right"
          right="right-1"
        />
      </div>
      <SizeInput
        title="Font size"
        fontSize={textfieldFontSize}
        updateFontSize={updateTextfieldFontSize}
        onFontSizeChange={onTextfieldFontSizeChange}
      />
    </div>
  );
}

export default TextfieldEditor;
